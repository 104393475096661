import { type FieldMetadata, type FieldName, FormProvider, useField } from "@conform-to/react";
import type * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";
import { type FormProps, Form as RemixForm } from "@remix-run/react";
import * as React from "react";

import { cx } from "#cva.config";
import type { FormContext, Wrapped } from "#node_modules/@conform-to/react/context";

import { useTranslation } from "react-i18next";

import { Label } from "./label";

const Form = React.forwardRef<
	HTMLFormElement,
	FormProps & {
		// biome-ignore lint/suspicious/noExplicitAny: typed any because is from conform-to
		context: Wrapped<FormContext<any, any>>;
	}
>(({ context, ...props }, ref) => {
	return (
		<FormProvider context={context}>
			<RemixForm {...props} ref={ref} />
		</FormProvider>
	);
});
Form.displayName = "Form";

type FormItemContextValue = FieldMetadata<unknown, Record<string, unknown>, string[]>;

const FormItemContext = React.createContext<FormItemContextValue>(
	{} as FieldMetadata<unknown, Record<string, unknown>, string[]>,
);

const FormItem = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement> & {
		name: FieldName<unknown, Record<string, unknown>, string[]>;
	}
>(({ className, name, ...props }, ref) => {
	const [meta] = useField(name);

	return (
		<FormItemContext.Provider value={meta}>
			<div className={cx("space-y-2", className)} ref={ref} {...props} />
		</FormItemContext.Provider>
	);
});
FormItem.displayName = "FormItem";

const FormLabel = React.forwardRef<
	React.ElementRef<typeof LabelPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
	const { errors, id } = React.useContext(FormItemContext);

	return <Label className={cx(errors?.length && "text-destructive", className)} htmlFor={id} ref={ref} {...props} />;
});
FormLabel.displayName = "FormLabel";

const FormControl = React.forwardRef<React.ElementRef<typeof Slot>, React.ComponentPropsWithoutRef<typeof Slot>>(
	({ ...props }, ref) => {
		const { errors, id, descriptionId, errorId } = React.useContext(FormItemContext);

		return (
			<Slot
				aria-describedby={!errors?.length ? `${descriptionId}` : `${descriptionId} ${errorId}`}
				aria-invalid={errors?.length ? true : undefined}
				id={id}
				ref={ref}
				{...props}
			/>
		);
	},
);
FormControl.displayName = "FormControl";

const FormDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
	({ className, ...props }, ref) => {
		const { descriptionId } = React.useContext(FormItemContext);

		return <p className={cx("text-foreground/75 text-sm", className)} id={descriptionId} ref={ref} {...props} />;
	},
);
FormDescription.displayName = "FormDescription";

const FormMessage = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
	({ className, children, ...props }, ref) => {
		const { errors, errorId } = React.useContext(FormItemContext);
		const body = errors?.length ? errors.join(" ") : children;
		const { t } = useTranslation("formMsg");

		if (!body) {
			return null;
		}

		if (typeof body === "string") {
			return (
				<p className={cx("font-medium text-destructive text-sm", className)} id={errorId} ref={ref} {...props}>
					{t(body)}
				</p>
			);
		}

		return (
			<p className={cx("font-medium text-destructive text-sm", className)} id={errorId} ref={ref} {...props}>
				{body}
			</p>
		);
	},
);
FormMessage.displayName = "FormMessage";

export { Form, FormControl, FormDescription, FormItem, FormLabel, FormMessage };
